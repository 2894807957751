.company-container{
    padding-left: 20%;
    padding-right: 20%;
    padding-top: 5%;
    padding-bottom: 20%;
    
    height: calc(100vh - 64px);

    background-color: #F9F9FB;

    color: var(--text-color);
}

.company-details-members {
    display: flex;
    flex-direction: column;
    gap: 10px;

    margin-top: 1%;
}

.company-details-member {
    display: grid;
    grid-template-columns: 2fr 4fr 4fr 6fr 2fr 1fr;
    align-items: center;
    gap: 10px;

    padding: 2%;
    padding-top: 1%;
    padding-bottom: 1%;
    border: 1px solid #E5E5E5;
    border-radius: 5px;

    background-color: white;
}

.company-details-member-picture {
    width: 50px;
    height: 50px;
    border-radius: 50%;

    object-fit: cover;

    border: 2px solid var(--revell);
}

.company-details-member-role {}

.company-details-member-role-admin {
    color: #006aef;
}


.profile-details-name {
    font-size: var(--large-fs);
    font-weight: var(--large-fw);
}

.profile-logout-container {
    margin-top: 10%;

    width: 150px;
    text-align: center;

    color: var(--text-color);
    padding: 5px 10px;
    border-radius: 5px;

    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;

    font-size: var(--medium-fs);
    font-weight: var(--medium-fw);

    background-color: white;
    border: 1px solid var(--revell);
}

.profile-logout-container:hover {
    background-color: var(--revell);
    border: 1px solid var(--text-color);
}

.companyprofile-details-container {
    font-size: var(--small-fs);
    font-weight: var(--small-fw);
    color: var(--text-color);
}

.company-details-input-disabled:hover {
    cursor: not-allowed;
    border-color: var(--revell);
}

.company-header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    margin-bottom: 5%;
}

.company-details-container {
    margin-top: 5%;
}

.company-details-header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    user-select: none;

    margin-bottom: 1%;
}

.company-details-invite-link {
    border-radius: 5px;
    border: 1px solid var(--revell);
    padding: 5px 10px;
    background-color: white;

    display: flex;
    justify-content: right;
    align-items: center;
    gap: 5px;
}

.company-details-invite-link:hover {
    cursor: pointer;
    border: 1px solid var(--text-color);
    background-color: var(--revell);
}

.company-details-member-settings-button {
    padding: 5px 10px;
    border-radius: 5px;
    text-align: center;
    cursor: pointer;

    display: flex;
    justify-content: center;
    align-items: center;

    outline: none;
}

.company-details-member-settings-button:hover {
    color: var(--bobyard-blue);
    background-color: var(--alice-blue);
}

.company-details-invites {
    display: flex;
    flex-direction: column;
    gap: 10px;

    padding-bottom: 20%;
}

.company-details-invite {
    display: grid;
    grid-template-columns: 6fr 3fr 1fr;
    align-items: center;
    gap: 10px;

    padding: 1% 2%;
    border: 1px solid var(--revell);
    border-radius: 5px;

    background-color: white;

    font-size: var(--small-fs);
    font-weight: var(--small-fw);
    color: var(--text-color);
}

.company-invitemodal-title {
    font-size: var(--medium-fs);
    font-weight: var(--medium-fw);
    color: var(--text-color);
}

.company-invitemodal-footer {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
}

.company-invitemodal-body-container{
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.company-invitemodal-email-container{
    display: grid;
    grid-template-columns: 9fr 1fr;
    gap: 10px;
}

.company-invitemodal-email-input{
    width: 100%;

    color: var(--text-color);
    font-size: var(--small-fs);
    font-weight: var(--small-fw);
    padding: 5px 10px;
    border-radius: 5px;
    border: 1px solid var(--revell);
}

.company-invitemodal-email-input:hover{
    border: 1px solid var(--bobyard-blue);
}

.company-invitemodal-email-input:focus{
    border: 1px solid var(--bobyard-blue);
    background-color: var(--alice-blue);
}

.company-invitemodal-email-title{
    font-size: var(--small-fs);
    font-weight: var(--large-fw);
    color: var(--text-color);
}

.company-invitemodal-body{
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.company-invitemodal-invites-title{
    font-size: var(--small-fs);
    font-weight: var(--large-fw);
    color: var(--text-color);
}

.company-invitemodal-invites{
    display: flex;
    flex-direction: column;
    gap: 10px;

    max-height: 65vh;
    overflow-y: auto;
}

.company-invitemodal-invites-none{
    font-size: var(--small-fs);
    font-weight: var(--small-fw);
    color: silver;
}

.company-invitemodal-invites-container{
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.company-details-invite-delete{
    display: flex;
    justify-content: center;
    align-items: center;

    color: var(--red);

    background-color: white;

    border-radius: 5px;
    padding: 5px;
}

.company-details-invite-delete:hover{
    cursor: pointer;
    background-color: var(--light-red);
}

.company-kickmodal-item{
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 10px;
    align-items: center;

    font-size: var(--small-fs);
    font-weight: var(--small-fw);
    color: var(--text-color);

    padding-left: 5%;
    padding-right: 5%;
}

.company-kickmodal-options{
    display: flex;
    justify-content: space-between;
}

.company-invitemodal-settings{
    display: flex;
    justify-content: left;
    align-items: center;
    gap: 5px;

    margin-bottom: 10px;
}