.takeoff-groupplaceholder-container {
    user-select: none;

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    font-size: 14px;
    font-weight: 400;

    height: 30px !important;

    color: gray;

    padding-right: 10px;
    width: 100%;

    /*font-family: 'Maison Neue';*/
}

.takeoff-groupplaceholder-title {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;

    padding-left: 20px;
}

.takeoff-groupplaceholder-link {
    text-decoration: none;
    color: gray;
}

.takeoff-groupplaceholder-link:hover {
    color: #006aef;
    cursor: pointer;
}