.measurementsidebar-container {
    display: block;
    user-select: none;

    overflow: hidden;
}

.measurementsidebar-header {
    height: 39px;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.measurementsidebar-toggle {
    height: 40px;

    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    align-items: center;

    border-bottom: 1px solid var(--revell);
}

.measurementsidebar-top {
    padding: 0px;

    border-bottom: 1px solid gainsboro;
    background-color: white;

    display: flex;
    flex-direction: column;
    justify-content: space-evenly;

    z-index: 101;
}

.measurementsidebar-title {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 5px;

    user-select: none;

    font-size: 16px;
}

.measurementsidebar-header-buttons {
    display: flex;
    flex-direction: row;
    justify-content: right;
    align-items: center;
}

.measurementsidebar-header-button {
    height: 39px;
    padding-left: 5px;
    padding-right: 5px;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.measurementsidebar-header-button:hover {
    cursor: pointer;
    background-color: var(--revell);
}

.measurementsidebar-header-button-disabled {
    cursor: not-allowed;
}

.measurementsidebar-header-button-disabled:hover {
    cursor: not-allowed;
}

.measurementsidebar-filter-container {
    position: relative;

    z-index: 1000 !important;
}

.measurementsidebar-header-input-container {
    padding-bottom: 5px;
    padding-top: 5px;
}

.measurementsidebar-newgroup-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    padding: 1%;
    padding-top: 0px;
}

.measurementsidebar-group-container {
    /*border: 1px solid lightgray;
    box-shadow: 0px 0px 1px 0px lightgray;*/
    border-radius: 5px;
}

.measurementsidebar-group-container-empty {
    color: lightgray;
}

.measurementsidebar-group-uom {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: right;

    border-radius: 2px;

    /*color: #313CA3;*/
    color: black;

    width: 90px;
    min-width: 90px;
    max-width: 90px;
}

.measurementsidebar-group-choose-uom-title {
    font-size: 0.8rem;
    padding-left: 2px;
    padding-bottom: 5px;
}

.measurementsidebar-group-choose-uom-item {
    display: grid;
    grid-template-columns: 1fr 4fr 1fr;
    align-items: center;
    gap: 5px;
}

.measurementsidebar-group-choose-uom-item:hover {
    cursor: pointer;
    background-color: aliceblue;
}

.measurementsidebar-group-choose-uom-selected {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.measurementsidebar-group-choose-uom-selected-icon {
    width: 10px;
    height: 10px;
    border-radius: 50%;

    background-color: var(--bobyard-blue);
}

.measurementsidebar-group-uom-abbr {
    height: 32px;
    width: 32px;

    text-align: right;

    display: flex;
    justify-content: right;
    align-items: center;
}

.measurementsidebar-group-uom-icon {
    width: 24px;
    padding-left: 4px;
    padding-right: 4px;
}

.measurementsidebar-group-uom-icon:hover {
    cursor: pointer;
    color: var(--bobyard-blue);
}

.measurementsidebar-groups-container {
    display: flex;
    flex-direction: column;
    max-height: 96vh;
    padding-bottom: 60vh;

    width: 100%;
    overflow: scroll;
    overflow-x: hidden;
}

.measurementsidebar-groups-container::-webkit-scrollbar,
.measurementsidebar-container::-webkit-scrollbar {}

.measurementsidebar-groups-container::-webkit-scrollbar-thumb,
.measurementsidebar-container::-webkit-scrollbar-thumb {
    background: Gray;
    border-radius: 3px;
    width: 5px;
    border-left: 4px solid transparent;
    background-clip: padding-box;
}

.measurementsidebar-groups-container::-webkit-scrollbar-track,
.measurementsidebar-container::-webkit-scrollbar-track {
    background: transparent;
    border-radius: 2px;
}

.measurementsidebar-groups-container::-webkit-scrollbar-thumb:hover,
.measurementsidebar-container::-webkit-scrollbar-thumb:hover {
    background: DimGray;
    border: 0;
    cursor: pointer;
}

.measurementsidebar-group-header {
    user-select: none;

    background-color: inherit;

    display: flex;
    flex-direction: row;
    align-items: center;

    font-size: 14px;
    font-weight: 400 !important;

    /*border: 1px solid transparent;*/

    width: 100%;
    height: 30px !important;
}

.measurementsidebar-group-header:hover {
    cursor: pointer;
}

.measurementsidebar-group-header-selected {
    /*border: 1px solid;*/
}

.measurementsidebar-group-header-multi-selected {
    background-color: aliceblue;
    border-color: aliceblue;
}

.measurementsidebar-group-header-empty {
    color: gainsboro !important;
}

.measurementsidebar-measurement-options {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: right;
    gap: 2px;

    padding-left: 4px;
    padding-right: 10px;
}

.measurementsidebar-sticky {
    position: relative;
    user-select: none;

    z-index: 100 !important;
}

.measurementsidebar-sticky-container {
    /*slight shadow on the bottom*/
    box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.1);
    margin-bottom: 5px;

    font-size: small;

    position: absolute;
    z-index: 100 !important;

    width: 100%;

    background-color: white;
}

.measurementsidebar-sticky-item {
    font-size: 14px;
    font-weight: 400;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: left;

    height: 32px;

    width: 100%;
}

.measurementsidebar-sticky-item:hover {
    cursor: pointer;
    background-color: whitesmoke;
}

.measurementsidebar-sticky-item-placeholder {
    width: 25px;
    border-right: 1px solid whitesmoke;
    margin-right: 12px;

    height: 32px;
}

.measurementsidebar-sticky-item-name {
    overflow: hidden;
    white-space: nowrap;
}

.measurementsidebar-group-title {
    font-size: 0.8rem;

    position: relative;

    min-width: 0;
    margin: 0px;
}

.measurementsidebar-group-title-short {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;

    width: 90%;
}

.measurementsidebar-group-title-long {
    position: absolute;
    background-color: white;
    width: fit-content;
    top: 23px;
    left: 0px;
    z-index: 999;

    max-width: 200px;

    margin-left: -2%;

    border-radius: 4px;
    padding-left: 2%;
    padding-right: 2%;

    background-color: #f5f5f5;
    border: 1px solid #006AFF;
    box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.2);
}

/*.measurementsidebar-group-title:hover {
    cursor: text;
    color: #006efa;
}

.measurementsidebar-group-title-input {
    padding-left: 4px;
    background-color: whitesmoke;

    border-radius: 3px;
}*/

.measurementsidebar-group-collapse-icon {
    width: 37px;
    padding-left: 14px;
    padding-right: 2px;

    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.measurementsidebar-group-collapse-icon-sybmol:hover {
    cursor: pointer;

    background-color: gainsboro;
    border-radius: 50%;
}

.measurementsidebar-group-title-input {
    font-size: 0.8rem;

    min-width: 0;
    margin: 0px;

    padding-left: 5px;

    width: 90%;

    background-color: white;
    border: 1px solid white;
    box-shadow: none;

    border-radius: 4px;
}

.measurementsidebar-group-title-input:hover {
    cursor: text;
    outline: none;
    border: 1px solid lightskyblue;
}

.measurementsidebar-group-title-input:focus {
    cursor: text;
    outline: none;
    border: 1px solid #006efa;
}

.measurementsidebar-group-body {
    border-left: 1px solid whitesmoke;
    margin-left: 4%;
}

.measurementsidebar-subgroups {
    padding-left: 2%;
}

.measurementsidebar-subgroup-container {
    /*border-right-width: 0px;
    border: 1px solid lightgray;
    box-shadow: 0px 0px 1px 0px lightgray;*/
    border-left: 1px solid rgb(240, 240, 240);
    border-radius: 5px;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
}

.measurementsidebar-subgroup-container-empty {
    color: lightgray;
}

.measurementsidebar-subgroup-header {
    display: grid;
    grid-template-columns: 1fr 19fr 1fr;
    align-items: center;

    border-radius: 5px;

    padding: 2%;
    padding-top: 1%;
    padding-bottom: 1%;
}

.measurementsidebar-subgroup-header:hover {
    cursor: pointer;
    /*background-color: whitesmoke;*/
}

.measurementsidebar-subgroup-title {
    font-size: 0.9rem;
}

.measurementsidebar-subgroup-measurements {}

.measurementsidebar-group-measurement {
    user-select: none;

    background-color: inherit;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    border-radius: 4px;

    font-size: 14px;
    font-weight: 400 !important;

    min-width: 0px;

    height: 30px !important;

    scroll-margin-top: 100px;
}

.measurementsidebar-group-name-container {}

.measurementsidebar-group-measurement:hover {
    /*background-color: #f0f8ff;*/
    /*background-color: whitesmoke;*/
    cursor: pointer;
}

.measurementsidebar-group-measurement-selected {
    background-color: #f0f8ff;
    color: #006efa;
}

.measurementsidebar-group-measurement-selected:hover {
    background-color: #f0f8ff;
    color: #006efa;
}

.measurementsidebar-group-measurement-differentpage {
    color: lightgray !important;
}

.measurementsidebar-group-measurement-multiselected {
    background-color: #f0f8ff;
}

.measurementsidebar-group-measurement-multiselected:hover {
    background-color: #f0f8ff;
}

.measurementsidebar-option-modal {
    margin-top: 6% !important;
    margin-left: 60% !important;
    width: 20% !important;
}

.measurementsidebar-option-modal-left {
    margin-top: 6% !important;
    margin-right: 60% !important;
    width: 20% !important;
}

.measurementsidebar-option-modal-title {
    font-size: 1.2rem;
    line-height: 0.8em;
}

.measurementsidebar-option-modal-body {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 5px;
}

.measurementsidebar-option-modal-item {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.measurementsidebar-option-modal-section-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    padding-bottom: 1%;
    min-height: 30px;
}

.measurementsidebar-option-modal-split-section {
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
    gap: 5px;

    width: 100%;
}

.measurementsidebar-option-modal-split-section-dot {
    display: grid;
    grid-template-columns: 3fr 1fr;
    align-items: center;
    gap: 5px;

    width: 100%;
}

.measurementsidebar-option-modal-section {
    width: 100%;

    padding: 2%;
    padding-top: 1%;
    padding-bottom: 1%;
    box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.1);
    border-radius: 5px;

    background-color: whitesmoke;
}

.measurementsidebar-option-modal-section-item {
    width: 100%;
    min-height: 0px;

    padding: 4%;
    padding-top: 2%;
    padding-bottom: 2%;
    box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.1);
    border-radius: 5px;

    background-color: whitesmoke;
}

.measurementsidebar-option-modal-section-row {
    width: 100%;
    min-height: 0px;

    padding: 4%;
    padding-top: 1%;
    padding-bottom: 0%;
    box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.1);
    border-radius: 5px;

    background-color: whitesmoke;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.measurementsidebar-option-modal-section-input {
    width: 100%;
    border: none;
    outline: none;
    font-size: 0.8rem;

    border-radius: 3px;

    padding-left: 3px;

    border: 1px solid white;
}

.measurementsidebar-option-modal-section-input:hover {
    border: 1px solid lightskyblue;
}

.measurementsidebar-option-modal-section-input:focus {
    border-color: #006efa;
}

.measurementsidebar-option-modal-section-title {
    font-size: 0.9rem;
    font-weight: bold;
}

.measurementsidebar-option-modal-section-info {
    font-size: 0.8rem;
}

.pagessidebar-option-modal {
    margin-top: 6% !important;
    margin-left: 20% !important;
    width: 20% !important;
}

.measurementsidebar-option-color-placeholder-background {
    padding-left: 4px;
    padding-right: 4px;

    height: 2.5vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.measurementsidebar-option-color-placeholder-background:hover {
    cursor: pointer;
    background-color: aliceblue;
}

.measurementsidebar-option-color-placeholder {
    /*round circle*/
    width: 16px;
    height: 16px;

    margin: 2px;

    border-radius: 50%;

    overflow: hidden;
}

.measurementsidebar-option-color-placeholder:hover {
    cursor: pointer;

    border-color: black;
}

.measurementsidebar-option-color-placeholder::-webkit-color-swatch {
    border: none;
    border-radius: 50%;
}

.measurementsidebar-option-color-input {
    border-radius: 50%;
}

.measurementsidebar-option-color-input:hover {
    cursor: pointer;
}

.measurementsidebar-option-input {
    width: 100%;
    border: none;
    outline: none;
    font-size: 0.9rem;

    border-radius: 3px;

    padding-left: 3px;

    border: 1px solid white;

    resize: none;
}

.measurementsidebar-option-input:hover {
    border: 1px solid lightskyblue;
}

.measurementsidebar-option-input:focus {
    border: 1px solid #006efa;
}

.measurementsidebar-measurement-name-full {
    position: absolute;
    background-color: white;
    width: fit-content;
    top: 23px;
    left: 0px;
    z-index: 10;

    max-width: 200px;

    overflow-wrap: break-word;

    left: -2%;
    border-radius: 4px;
    padding-left: 2%;
    padding-right: 2%;

    background-color: #f5f5f5;
    border: 1px solid #006AFF;
    box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.2);
}

.measurementsidebar-measurement-name-short {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.measurementsidebar-measurement-name {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;

    /*font-family: 'Maison Neue';*/
}

/*.measurementsidebar-measurement-name:hover {
    cursor: text;
    color: #006efa;
}*/

@keyframes show {
    0% {
        opacity: 0;
    }

    90% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

.measurementsidebar-measurement-name-popup {
    position: absolute;
    z-index: 1000;
    top: -31px;
    left: 0px;

    white-space: nowrap;
    width: fit-content !important;

    background-color: white;
    border-radius: 5px;
    border: 1px solid lightgray;
    box-shadow: 0px 0px 1px 0px lightgray;

    animation: show 1000ms linear 0s 1;
}

.measurementsidebar-measurement-name-input {
    border: 1px solid var(--bobyard-blue);
    margin-left: -3px;
    padding-left: 2px;

    height: 32px;
    outline: none;
}

.measurementsidebar-measurement-option-gear {
    text-align: right;
    font-size: 0.6rem;

    display: flex;
    flex-direction: row;
    align-items: center;
}

.measurementsidebar-measurement-option-gear:hover {
    cursor: pointer;
    color: #006efa;
}

.measurementsidebar-measurement-option-gear-hidden {
    visibility: hidden;
}

.measurementsidebar-measurement-option-eye {
    text-align: right;
    font-size: 0.8rem;
}

.measurementsidebar-measurement-option-eye:hover {
    cursor: pointer;
    color: #006efa;
}

.measurementsidebar-measurement-option-eye-hidden {
    visibility: hidden;
}

.measurementsidebar-measurement-dots-container {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    align-items: center;
    justify-content: left;
    align-self: center;
    gap: 2px;

    padding-left: 5%;
    border-radius: 5px;

    /*background-color: #f8fdff;*/
}

.measurementsidebar-measurement-dot-container {
    border: 1px solid #f8fdff;
    box-shadow: none;
    border-radius: 4px;
}

.measurementsidebar-measurement-dot-container:hover {
    border: 1px solid #006efa;
}

.measurementsidebar-measurement-dot {
    width: 50px;

    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    border-radius: 4px;

    outline: none;
}

.measurementsidebar-measurement-dot:hover {
    cursor: pointer;
}

.measurementsidebar-measurement-dot-selected {
    color: #006efa;
}

.measurementsidebar-measurement-dot-image {
    border-radius: 3px;
    border: 1px solid whitesmoke;

    /*stretch to square*/
    width: 50px;
    height: 50px;
    object-fit: contain;

    overflow: hidden;

    display: flex;
    justify-content: center;
    align-items: center;

    background-color: white;
}

.measurementsidebar-measurement-dot-image:hover {
    cursor: pointer;
}

.measurementsidebar-measurement-dot-image-wrapper {
    max-width: 48px;
    max-height: 48px;

    border-radius: 2px;

    display: flex;
    justify-content: center;
    align-items: center;
}

.measurementsidebar-measurement-dot-image-wrapper img {
    max-width: 48px;
    max-height: 48px;

    object-fit: contain;
    overflow: hidden;
}

.measurementsidebar-measurement-dot-number {
    display: flex;
    flex-direction: column;
    width: 50px;
    height: 50px;
    text-align: center;
    justify-content: center;
    align-items: center;

    border-radius: 5px;
    border: 1px solid gray;
    background-color: white;
}

.measurementsidebar-measurement-dot-delete-container {
    position: absolute;
    top: 0px;
    right: -2px;

    height: 15px;
    width: 15px;

    border-radius: 50%;
}

.measurementsidebar-measurement-dot-delete-container:hover {
    background-color: #ffcccc;
    cursor: pointer;
}

.measurementsidebar-measurement-dots-pagination {
    margin-top: 5px;
    margin-bottom: 5px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.measurementsidebar-color-icon {
    width: 16px !important;
    height: 16px !important;
    min-width: 16px !important;
    border-radius: 50%;
}

@keyframes show {
    0% {
        opacity: 0;
    }

    90% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

.measurementsidebar-resizer {
    position: absolute;
    left: -2px;

    width: 2px;
    top: 0px;
    height: 96vh;
    right: auto;

    cursor: col-resize;
    resize: horizontal;

    border-right: 1px solid gainsboro;
}

.measurementsidebar-resizer:hover {
    left: -2px;
    background: var(--bobyard-blue);
    border-color: var(--bobyard-blue);
}

.measurementsidebar-resizing-body {
    padding: 4% 6%;
}

.measurementsidebar-left-resizer {
    position: absolute;
    right: -2px;

    width: 2px;
    top: 0px;
    height: 96vh;

    cursor: col-resize;
    resize: horizontal;

    border-left: 1px solid gainsboro;
}

.measurementsidebar-left-resizer:hover {
    right: -2px;
    background: var(--bobyard-blue);
    border-color: var(--bobyard-blue);
}

.measurementsidebar-search-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    padding: 1%;
    padding-top: 0px;
}

.measurementsidebar-search-container {
    cursor: text;
}

.measurementsidebar-independent-container {
    padding-bottom: 10%;
}

.measurementsidebar-treeitem {
    margin: 0px !important;
}

.measurementsidebar-measurement-icon {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: left;

    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;

    outline: none !important;
}

.measurementsidebar-measurement-icon-container {
    display: flex;
    align-items: center;

    height: 32px;
    padding-right: 8px;
    padding-left: 8px;

    outline: none !important;
}

.measurementsidebar-measurement-details {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: right;

    text-align: right;
}

.measurementsidebar-measurement-container {
    user-select: none;

    min-width: 0;
    min-height: 0;

    align-items: center;

    padding: 0px;
    font-size: 0.8rem;

    border-radius: 5px;
}

.measurementsidebar-measurement-container:hover {
    /*background-color: whitesmoke;*/
}

.measurementsidebar-dragging-overlay {
    margin-left: 20%;

    padding-left: 10px;
    font-size: 0.8em;
    background-color: white;
    border-radius: 5px;
    box-shadow: 0px 0px 3px 0px lightgray;

    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
}

.measurementsidebar-measurement-hide {
    display: flex;
    justify-content: center;
}

.measurementsidebar-measurement-hide:hover {
    cursor: pointer;
    color: #006AFF;
}

.measurementsidebar-dragging-overlay-dots {
    min-width: 150px !important;
    width: 150px !important;

    background-color: white;
    border-radius: 2px;
    box-shadow: 0px 0px 3px 0px lightgray;
}

.measurementsidebar-measurement-details-value {
    min-width: 90px;
    width: 90px;
    max-width: 90px;

    font-size: 12px;
    line-height: 21px;

    color: #898990;

    /*font-family: 'Maison Neue';*/
}

.measurementsidebar-measurement-details-value-abbr {
    width: 32px;

    text-align: right;

    display: flex;
    justify-content: right;
    align-items: center;

    font-size: 12px;
    line-height: 21px;

    color: #898990;

    /*font-family: 'Maison Neue';*/
}

.measurementsidebar-measurement-details-icons {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: right;
    gap: 2px;

    padding-left: 4px;
    padding-right: 10px;

    min-width: 30px;
    width: 30px;
    max-width: 30px;
}

.countmeasurement-dot-preview-options-button {
    position: absolute;
    left: 1px;
    top: 1px;
    border-radius: 50%;
}

.countmeasurement-dot-preview-options-button:hover {
    cursor: pointer;
    background-color: #006AFF;
    color: white;
}

.measurementsidebar-importgroupmodal-container {
    height: calc(60vh - 69px);
    overflow: hidden;
    overflow-y: scroll;
    padding-bottom: 25%;
}

.importgroupmodal-icon {
    width: 30px;
    height: 30px;

    display: flex;
    justify-content: center;
    align-items: center;

    border-radius: 5px;
}

.importgroupmodal-icon:hover {
    cursor: pointer;

    background-color: var(--alice-blue);
    color: var(--bobyard-blue);
}

.importgroupmodal-icon-active {
    width: 30px;
    height: 30px;

    display: flex;
    justify-content: center;
    align-items: center;

    border-radius: 5px;

    background-color: var(--alice-blue);
    color: var(--bobyard-blue);
}

.importgroupmodal-icon-active:hover {
    cursor: pointer;

    background-color: var(--alice-blue);
    color: var(--bobyard-blue);
}

.importgroupmodal-search-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    gap: 5px;
}

.importgroupmodal-header {
    width: 100%;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    padding: 8px 10px;
}

.importgroupmodal-expand {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.importgroupmodal-buttons {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    padding-left: 10px;
    padding-right: 10px;
    padding-top: 5px;
    padding-bottom: 5px;

    border-bottom: 1px solid gainsboro;
}

.measurementsidebar-sticky-item-color {
    padding-left: 14px;
    padding-right: 2px;
}

.measurementsidebar-sticky-item-value {
    width: 120px;
    text-align: right;
}

.measurementsidebar-sticky-item-uom-abbr {
    padding-right: 54;
    width: 32px;

    text-align: right;
}